<template>
  <div>
    <div id="page-content">
       <!-- <div class="dispflex" style="align-items: baseline">
      <h1 class="hdrtitle ml-2" style="margin-top: 0px;">Fees Management</h1>
      <button type="button" class="backbtn" @click="previousRoute">
              <i class="fa fa-arrow-left"></i>
              </button>
       </div> -->
       <div class="webNew-dispflex"   >
       
       <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Fees Management
       </h4>
      </div>
      <div class="row mt-2" id="showsummary">
        <div class="col-md-12 col-lg-12 col-sm-12">
          <div class="widjet m-2">
            <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" 
                  ><i class="far fa-list-alt"></i
                ></span>
                <h1>Fee Summary  <span  style="padding-left: 67%;">Plan name : {{ planDetails.planName || '' }}</span></h1>
              
              </div>
              <div class="mr-1">
                      
                      <button
                        class="btn gry_bg tooltipt mpopup twoicn"
                         @click.prevent="generateFeePlan" 
                      >
                        <i class="fas fa-file-invoice user1"></i>
                          <p class="user2"><i class="fas fa-plus"></i></p>
                        <!-- <span class="tooltiptext" v-if="planDetails.status != 'Approved' && planDetails.status != 'Active' || studentsCount == 0">Fee Plan is not Approved & Add Min. One Student</span> -->
                        <span class="tooltiptext">Assign Fee Plan</span>
                      
                      </button>
                      <button
                        class="btn gry_bg tooltipt mpopup twoicn"  @click.prevent="openRevokeFeePopUp"
                      >
                        <i class="fas fa-reply"></i>
                        
                        <span class="tooltiptext">Revoke</span>
                      </button>
                    </div>
             
            </div>

            <div class="widjetcontent mt-1">
              <div
                class="widjethdr dispflex"
                style="align-items: baseline; border: none"
              >
                <div style="width: auto">
                  <h1 style="margin: 0">Forecast Amount </h1> 
                  <!-- <span  >
                         
                          </span> -->
                </div>
                
                <div class="admintbl mt-1">
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Class / Students</th>
                        <th scope="col">Total Students</th>
                        <th scope="col">No. of Students fees assigned</th>
                        <th scope="col">No. of New Students</th>
                        <th scope="col">Academic Year</th>
                        
                        <th scope="col" class="text-right">Total Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr :key="indextr" v-for="(tr, indextr) in classList">
                        <td data-label="Class / Students">{{ tr.class }}</td>
                        <td data-label="No. of Students">{{ tr.noStudent }}</td>
                        <td data-label="No. of Students">
                          <span  v-if="feeGenerated(tr.totalStudentList) > 0" style="font-weight: 600;color: cadetblue;" @click.prevent="openPopUpForStudentView(tr.totalStudentList,'old')"><a>{{ feeGenerated(tr.totalStudentList) }} - Students</a> </span>
                          <span v-else >
                          {{ feeGenerated(tr.totalStudentList) }}
                          </span>
                        </td>
                        <td data-label="No. of Students">
                          <span  v-if="feeNotGenerated(tr.totalStudentList) > 0" style="font-weight: 600;color: cadetblue;" @click.prevent="openPopUpForStudentView(tr.totalStudentList,'new')"><a>{{ feeNotGenerated(tr.totalStudentList) }} - Students</a> </span>
                          <span v-else >
                          {{ feeNotGenerated(tr.totalStudentList) }}
                          </span>
                        </td>
                        <td data-label="No. of Students">{{ planDetails.academicYear.from + " - " + planDetails.academicYear.to }}</td>
                        
                        <td data-label="Total Amount ($)" class="text-right">
                          {{ gettotalAmount(tr.noStudent) }}
                        </td>
                      </tr>
                      <tr class="tot">
                        <td
                          colspan="4"
                          class="text-right"
                          data-label="Total Amount ($)"
                        >
                          Total Amount ($): <b>{{ grandTotalAmount() }}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="widjetcontent mt-1">
              <div class="m-3">
                <div
                  class="widjethdr dispflex"
                  style="align-items: baseline; border: none; padding: 0"
                >
                  <div style="width: auto">
                    <h1 style="margin: 0">Fee Summary for</h1>
                  </div>
                </div>
                 <div class="admintbl pb-3 mt-1">
                   <table>
                      <thead>
                          <tr>
                              <th>Term Name</th>
                              <th>Fees Type</th>
                              <th>Invoice Date</th>
                              <th>Invoice Due Date</th>
                              <th>Billing Frequency</th>
                              <th>Refundable</th>
                              <th>Discount</th>

                              <th>Fee Amount ($)</th>
                              <th>Total Amount ($)</th>
                          </tr> 
                      </thead>
                      <tbody v-for="(data, index) in Object.keys(summaryList)" :key="index">
                        <tr class="trmname">
                          <td colspan="7" data-label="Term Name" align="left"><b>{{ data }}</b>&nbsp;</td>
                          <td style="text-align:left"><b>{{ totalFeesTerm(data) | currencyFormat }}</b>&nbsp;</td>
                          <td style="text-align:center"><b>{{ grandtotalFeesTerm(data) | currencyFormat }}</b>&nbsp;</td>

                        </tr>
                        <tr v-for="(ter, issndex) in summaryList[data]"
                        :key="issndex">
                        <div class="sdtrmname" style="display:none">{{ data }}: {{ totalFeesTerm(data) | currencyFormat }}</div> 
                          <td align="left"></td>
                          <td data-label="Fees Type">{{ ter.feeTypeId ? getFeeTypeName(ter.feeTypeId) : ''  }}&nbsp;</td>
                          <td data-label="Invoice Date"> {{ ter.feesStartDate | dateFormat }}&nbsp;</td>
                          <td data-label="Invoice Due Date"> {{ ter.feesEndDate | dateFormat }}&nbsp;</td>
                          <td data-label="Billing Frequency">{{ ter.billingFrequency }}&nbsp;</td>
                          <td data-label="Refundable"> {{ ter.isRefundable ? "yes" : "No" }}&nbsp;</td>
                          <td data-label="isDiscountable"> {{ ter.isDiscount ? getdiscountAmount(ter.amount) : "0" }}&nbsp;</td>

                          
                          <td data-label="Fee Amount ($)">{{ ter.amount | currencyFormat }}&nbsp;</td>
                          <td data-label="Total Amount ($)" class="tblnone">&nbsp;</td>
                        </tr>
                      </tbody>
                   </table>
                   <table border="0">
                     <tr class="tot">
                       <td colspan="7" style="text-align:right;font-weight:600"><b>Fee Total Amount :</b></td>
                       <td style="text-align:left;font-weight:600"><b>{{  totalFeeTypeAmount | currencyFormat}}</b>&nbsp;</td>
                     </tr>
                     <tr class="tot">
                       <td colspan="7" style="text-align:right;font-weight:600"><b>Grand Total Amount :</b></td>
                       <td style="text-align:left;font-weight:600"><b>{{ feeAmountAll | currencyFormat}}</b>&nbsp;</td>
                     </tr>
                   </table>
                    <div
                    class="alert alert-info mt-3"
                    style="padding: 0.35rem 0.65rem"
                  >
                    <ul style="margin: 0">
                      <li>
                       {{ planDetails.discount && planDetails.discount.mode ? ( planDetails.discount.mode == 'Rs($)' ?  'Rs' + " " + planDetails.discount.discountAmountValue : planDetails.discount.discountPercentageValue + '%'  ): '0%' }} discount is applicable if paid initially for full
                        term within the due date
                      </li>
                      <li>
                        {{ planDetails.penalty && planDetails.penalty.mode ? ( planDetails.penalty.mode == 'Rs($)' ?  'Rs' + " " + planDetails.penalty.penaltyAmountValue : planDetails.penalty.penaltyPercentageValue + '%'  ): '0%' }} Late Fee charges are levied if the fees are not paid
                        within the due date
                      </li>
                      <li>
                        Sibling Discount will be applicable if the student is
                        eligible and it will reflect in Student Fee Summary
                        Section
                      </li>
                      <li>
                        *Discount & other charges are applied/included in Final
                        Billing Summary
                      </li>
                    </ul>
                  </div>
                 </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="viewstudpop" class="modal" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closeStudentViewPopUp">×</button>
      <div class="contact-form">
       <div class="row mt-2">	
				<div class="col-md-12 col-lg-10 col-sm-12">
					<h2>Students</h2> 
				</div>
				<div class="col-md-12 col-lg-2 col-sm-12">
					<!-- <button type="button" @click.prevent="closeStudentViewPopUp" class="btn btncl clsmdl">
				   <span>Cancel</span></button> -->
				</div>
			</div>
        <br />
        <div style="height: 350px; overflow-y: auto">
         <div class="tbllist mt-1">
                <div class="restable">
                  <div class="resrow resheader">
				         	<div class="cell">S.No</div>
                    <div class="cell">Student Name</div>
				
                  </div>
                  <div
                    class="resrow"
                    v-for="(student, index) in pendingStudentList"
                    :key="index"
                  >
                    <div class="cell" data-title="Student Name">
                      {{ index + 1 }}
                    </div>
                    <div class="cell" data-title="Student Name">
                      <a style="padding-left: 6px;" href="javascript:void(0);"  @click.prevent="redirectRoute(`/admission/student/view/details/${student._id}`)">{{ student.firstName + " " + student.lastName }} </a>
                    </div>
				
                  </div>
                </div>
        </div>
			
        </div>
      </div>
    </b-modal>
    <b-modal id="revokeFeePopUp" class="modal" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closeRevokeFeePopUp">×</button>
      <div class="contact-form">

        <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Confirmation</h2>
        <br />
        Invoice cannot be revoked for the Terms where the fee is already Paid through Online/Offline Mode. Vidhyaan doesn't support Refund Process.
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
           @click.prevent="revokeFeePlan" 
            type="button"
            class="btn btnsml"
            id="svbtn"
          >
              <span>I Agree</span>
          </button>
          <button @click.prevent="closeRevokeFeePopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>Cancel</span>
          </button>
        </div>
      </div>
        
      </div>
    </b-modal>
  </div>
</template>
<script>
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";

export default {
  name: "vi-fees-management",
  data() {
    return {
      classList: [],
      summaryList: {},
      planDetails: {},
      feesTypeList: [],
      feeAmountAll: 0,
      pendingStudentList: [],
      totalFeeTypeAmount: 0,
      totalNoOfStudents: 0,
      revokeCount: 0
    };
  },
  created() {
    this.getFeesTypeList();
    this.getSummaryDetails();
  },
  computed: {
     studentsCount() {
      if (this.classList.length > 0) {
        return this.classList
          .map((o) => o.noStudent)
          .reduce((a, c) => {
            return a + c;
          });
      } else {
        return 0;
      }
     },
   
  },
  methods: {
    previousRoute(){
    //  let data = localStorage.getItem('previousTab');
    //  localStorage.setItem("activeTab",data);
    //  localStorage.setItem("previousTab",data);
    //  this.$router.go(-1)
       localStorage.setItem("activeTab", '/feesManagement/home');
        localStorage.setItem("previousTab", '/feesManagement/home');
        this.$router.push({ path: '/feesManagement/home' });
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    getdiscountAmount(data){
      if(this.planDetails && this.planDetails.discount && this.planDetails.discount.mode){
            if(this.planDetails.discount.mode == 'Rs($)'){
           //  let value = `Rs: ${this.planDetails.discount.discountAmountValue}`
             return this.planDetails.discount.discountAmountValue

            }else{
              return this.planDetails.discount.discountPercentageValue + "%"

            }
          }else{
            return 0
          }
     },
    totalFeesTerm(value) {
      if (this.summaryList[value]) {
        //let feeAmountAll
        return this.summaryList[value]
          .map((o) => o.amount)
          .reduce((a, c) => {
            return a + c;
          });
        

      } else {
        return 0;
      }
    },
    grandtotalFeesTerm(value){
      if (this.summaryList[value]) {
        let getvalue = 0
        for(let x of this.summaryList[value]){
          getvalue = (getvalue + x.amount)
        }

        const checkDiscount = this.summaryList[value].filter( x => x.isDiscount).length

         if(this.planDetails && this.planDetails.discount && this.planDetails.discount.mode){
            if(this.planDetails.discount.mode == 'Rs($)'){
              getvalue = ((getvalue - (Number(this.planDetails.discount.discountAmountValue) * checkDiscount)))
              return getvalue
            }else{
              let getDiscountValue =  (getvalue * (Number(this.planDetails.discount.discountPercentageValue) * checkDiscount )) / 100;
              return getvalue - getDiscountValue;
            }
            
          }else{
            return getvalue
          }

        // return this.summaryList[value]
        //   .map((o) => o.amount)
        //   .reduce((a, c) => {
        //     return a + c;
        //   });

        
      } else {
        return 0;
      }
    },
    gettotalAmount(noStudent) {
      return noStudent * this.totalFeeTypeAmount;
    },
    grandTotalAmount() {
      return this.totalNoOfStudents * this.totalFeeTypeAmount;
    },
    getFeeTypeName(id){
     if(id){
      const details = this.feesTypeList.find( x => x._id == id)
      if(details){
         return details.name
      }else{
        return ''
      }
     }else{
       return ''
     }
    },
    feeNotGenerated(studs){
     if(studs.length > 0){
      const details = studs.filter( x => x.feeTermGenerated == false )
      if(details.length > 0){
       
         return details.length
      }else{
        return 0
      }
     }else{
       return 0
     }

    },
    feeGenerated(studs){
     if(studs.length > 0){
      this.revokeCount = 0
      
      const details = studs.filter( x => x.feeTermGenerated && x.feePlanId && x.feePlanId == this.$route.params.id )
      if(details.length > 0){
        return details.length
      }else{
        return 0
      }
     }else{
       return 0
     }

    },
    openPopUpForStudentView(studs,type){
        if(type == 'new'){
         this.pendingStudentList = studs.filter( x => x.feeTermGenerated == false )
        }else{
        this.pendingStudentList = studs.filter( x => x.feeTermGenerated && x.feePlanId && x.feePlanId == this.$route.params.id )
        }
        
        this.$bvModal.show("viewstudpop");
  	},
   
    openRevokeFeePopUp(){
      if(this.studentsCount > 0){
       this.$bvModal.show("revokeFeePopUp");
      }else{
        this.$toasted.error('There are no students unable to revoke');
      }
      
    },
    closeRevokeFeePopUp(){
      this.$bvModal.hide("revokeFeePopUp");
    },
    closeStudentViewPopUp(){
          this.$bvModal.hide("viewstudpop");
    },
    async getFeesTypeList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          "/feesManagement/getFeesTypeList",
          userData.token
        );
        if (response.isSuccess) {
          this.feesTypeList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async getSummaryDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/feesManagement/getSummaryDetails?planId=${this.$route.params.id}`,
          userData.token
        );
        if (response.isSuccess) {

          const result = secureUI.secureGet(response.data);
          this.classList = result.classList || [],

          this.summaryList = result.feeTypeList || {};
          
          this.planDetails = result.planDetails || {};

          if (this.classList.length > 0) {
            this.totalNoOfStudents = this.classList.map((o) => o.noStudent).reduce((a, c) => { return a + c; });
          } else {
            this.totalNoOfStudents = 0;
          }

          this.totalFeeTypeAmount = result.totalAmount;

          this.$toasted.success(response.message);

        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async generateFeePlan() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {

       // :disabled="planDetails.status != 'Approved' && planDetails.status != 'Active' || studentsCount == 0"
       
        if(this.planDetails.status == 'Approved' || this.planDetails.status == 'Active'){
          if(this.studentsCount > 0){

        
            const response = await ViService.viXPut(
                `/feesManagement/updateFeePlan/generateFeePlan/${this.$route.params.id}`,
                userData.token,
                userData.token
              );
          
            if (response.isSuccess) {
              this.$toasted.success(response.message);
              this.getSummaryDetails();
              this.setInvoiceCounts()
            
            } else {
              this.$toasted.error(response.message);
              if(response.message == 'Your session has expired, please login'){
                    localStorage.removeItem('user');
                    this.$router.push('/login');
            }
            }
        }else{
          this.$toasted.error('There are no students unable to generate fee plan');
         // this.$toasted.error('The fee plan is not yet approved');
        }
       }else{
        this.$toasted.error('The fee plan is not yet approved');
       }
      }
    },
    async setInvoiceCounts() {
          let userData = secureUI.sessionGet("user");

          if (!userData) {
            this.$toasted.error("Please login and do the action");
            this.$router.push('/login');
          } else {
             this.viLoader = true
            const response = await ViService.viXGet(
              "/feesManagement/getAllCounts?viewType=list",
              userData.token
            );
            if (response.isSuccess) {
            
              const objResult = secureUI.secureGet(response.data);

              let getCounts = this.$store.getters.getFeesManagementObjCount

              getCounts.feesPlanGenerated = objResult.FeesPlanGenerated ? objResult.FeesPlanGenerated : getCounts.feesPlanGenerated

              this.$store.dispatch('setFeesManagementObjCount', getCounts);

              // this.$store.dispatch('setFeesManagementObjCount', {

              // feeType : objResult.FeeType ? objResult.FeeType : 0,
              // feesPlan : objResult.FeesPlan ? objResult.FeesPlan : 0,
              // adhocFees : objResult.AdhocFees ? objResult.AdhocFees : 0,
              // assignedFeesType : objResult.AssignedFeesType ? objResult.AssignedFeesType : 0,
              // discountsAndPenalties : objResult.DiscountsAndPenalties ? objResult.DiscountsAndPenalties : 0,
              // feesPlanGenerated : objResult.FeesPlanGenerated ? objResult.FeesPlanGenerated : 0
              // });

              //this.$store.dispatch('setFetchFeesObjCount',true)

              //this.$toasted.success(response.message);
            } else {
              this.$toasted.error(response.message);
               if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
            }
          }
        },
    async revokeFeePlan() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let revokeList = []
        
        for(let x of this.classList){
          if(x.totalStudentList.length > 0){
          for(let y of x.totalStudentList){
            if(y.feeTermGenerated){
                revokeList.push(y)
            }
           
          }
        }
        }
        
       if(revokeList.length > 0){
         
         const response = await ViService.viXPut(
            `/feesManagement/revoke/students/generateFeePlan/${this.$route.params.id}`,
            userData.token,
            userData.token
          );
      
        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.$bvModal.hide("revokeFeePopUp");
           this.getSummaryDetails();
         
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }else{
       this.$toasted.error('fee plan is already revoked');
      }
      }
    },
    
  },
};
</script>
<style scoped>
.cust-bg {
	margin-left: 0px;
    background-color: lavender;
    margin-right: 0px;
	color: darkgoldenrod;
}
.cust-lr {
	margin-left: 0px;
    margin-right: 0px;
	
}
</style>